.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 750px; /* Maximo tamaño de la imagen */
  height: 500px; /* Altura fija más grande para que no se corten las fotos */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 2px solid #ff63b5; /* Borde rosa */
}

/* Aquí cambiamos la forma en que se ajustan las imágenes */
.carousel-container img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Mantiene la imagen completa, sin recortarla */
  object-position: center center; /* Asegura que la imagen esté centrada */
  transition: transform 0.5s ease-in-out;
}

.carousel-container img:hover {
  transform: scale(1.05);
}

.caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 25px;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: #ff63b5;
  font-size: 2.5rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

button:hover {
  color: #ff409f; /* Rosa más fuerte al pasar el ratón */
}

/* Diseño responsive para móviles */
@media (max-width: 768px) {
  .carousel-container {
    height: 300px; /* Reducir altura para pantallas pequeñas */
    max-width: 100%;
  }

  .caption {
    font-size: 1.2rem;
  }

  button {
    font-size: 2rem; /* Reducir tamaño de los botones */
  }
}
