.App {
  text-align: center;
  background-color: #fef0f5; /* Rosa muy suave */
  font-family: "Poppins", sans-serif;
}

.App-header {
  background-color: #ff63b5; /* Rosa vibrante */
  padding: 30px;
  color: white;
  border-bottom: 2px solid #f8a6b1;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Dancing Script", cursive;
  text-transform: uppercase;
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Poppins:wght@300&display=swap");
